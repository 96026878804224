import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from '../templates/contribute.module.css'
import * as numEmojis from '../components/number-emojis'

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: {regex: "/wiki\/Translations.md$/"}) {
      fileAbsolutePath
      html
    }

    allFile(filter: {absolutePath: {regex: "/wiki\/Translations.md$/"}}) {
      edges {
        node {
          changeTime
        }
      }
    }
  }
`

export default function Translations({data}) {
  const {markdownRemark} = data
  const html = markdownRemark.html
    .replace(/:one:/g, numEmojis.ONE)
    .replace(/:two:/g, numEmojis.TWO)
    .replace(/:three:/g, numEmojis.THREE)
    .replace(/:four:/g, numEmojis.FOUR)
    .replace(/:five:/g, numEmojis.FIVE)
    .replace(/:six:/g, numEmojis.SIX)
    .replace(/:seven:/g, numEmojis.SEVEN)
    .replace(/:eight:/g, numEmojis.EIGHT)
    .replace(/:nine:/g, numEmojis.NINE)
  const changeTime = data.allFile.edges[0].node.changeTime.split('T')[0]
  const title = 'Translate'
  return (
    <Layout>
      <SEO title={title} />
      <div className={styles.container}>
        <h1>{title}</h1>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{__html: html}}
        />
        <p>
          This file comes from our{' '}
          <a href="https://gitlab.com/staltz/manyverse/-/wikis/Translations">
            Wiki page
          </a>{' '}
          on GitLab. <em>Last updated on {changeTime}</em>.
        </p>
      </div>
    </Layout>
  )
}
